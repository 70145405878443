<template>
  <b-overlay
    variant="white"
    :show="isLoading"
    spinner-variant="primary"
    blur="0"
    opacity=".5"
    rounded="sm"
  >
    <b-row class="match-height">
      <b-col cols="12">
        <b-card :title="this.$route.meta.title">
          <b-card-body class="p-0">
            <b-row>
              <b-col cols="12" class="mb-2">
                <h4>Pengajuan Pengeluaran Riil</h4>
                <p class="font-italic">
                  Untuk pengajuan pengeluaran riil, silahkan siapkan kumpulan
                  kwitansi
                </p>
              </b-col>
              <b-col cols="12" md="6" class="mb-2">
                <h6>No / Tanggal SPPD</h6>
                <p class="card-text mb-25">
                  {{ sppd.sppd_number || '-' }} /
                  {{ sppd.letter_date | moment('DD MMMM YYYY') }}
                </p>
              </b-col>
              <b-col cols="12" md="6" class="mb-2">
                <h6>
                  {{ employee.type === 'pegawai' ? 'NIPPOS' : 'NIK' }} / Nama
                </h6>
                <p class="card-text mb-25">
                  {{
                    employee.type === 'pegawai' ? employee.nippos : employee.nik
                  }}
                  /
                  {{
                    employee.type === 'pegawai' ? employee.nama : employee.name
                  }}
                </p>
              </b-col>
              <b-col cols="12" md="6" class="mb-2">
                <h6>Jenis Fasilitas</h6>
                <p class="card-text mb-25">
                  {{ sppdFacilityType }}
                </p>
              </b-col>
              <b-col cols="12" md="6" class="mb-2">
                <h6>Jabatan</h6>
                <p class="card-text mb-25">
                  {{
                    employee.type === 'pegawai'
                      ? employee.descjabatan
                      : employee.position
                  }}
                </p>
              </b-col>
              <b-col cols="12" md="6" class="mb-2">
                <h6>Bagian/Unit Kerja</h6>
                <p class="card-text mb-25">
                  {{ employee.namabagian || '-' }}
                </p>
              </b-col>
              <b-col cols="12" md="6" class="mb-2">
                <h6>Kantor/Kota Tujuan</h6>
                <p class="card-text mb-25">
                  {{ sppd.destination_office || '-' }}
                </p>
              </b-col>
              <b-col cols="12" md="6" class="mb-2">
                <h6>Tanggal Berangkat</h6>
                <p class="card-text mb-25">
                  {{ sppd.departure_date | moment('DD MMMM YYYY') }}
                </p>
              </b-col>
              <b-col cols="12" md="6" class="mb-2">
                <h6>Tanggal Kembali</h6>
                <p class="card-text mb-25">
                  {{ sppd.return_date | moment('DD MMMM YYYY') }}
                </p>
              </b-col>
              <b-col v-if="sppd.real_expense_step" cols="12" md="6" class="mb-2">
                <h6>Status</h6>
                <p class="card-text mb-25">
                  {{ sppd.status_real_expense_detail || '-' }}
                </p>
              </b-col>
            </b-row>
            <div>
              <div
                v-if="
                  Object.keys(sppd).length &&
                    !sppd.real_expense_step &&
                    sppd.general_status === 'Sudah melakukan perjalanan'
                "
                class="d-flex align-items-start justify-content-between mb-2"
              >
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="button"
                  class="mr-1"
                  variant="outline-primary"
                  @click="tambahPengeluaran"
                >
                  <plus-icon size="1x" />
                  <span> Tambah Pengeluaran</span>
                </b-button>
              </div>
              <b-table
                striped
                hover
                responsive
                show-empty
                :items="items"
                :fields="fields"
              >
                <template #cell(no)="data">{{ data.index + 1 }}</template>

                <template #cell(total_cost)="data">
                  <span class="text-capitalize d-block text-right">
                    {{ data.item.total_cost | currency }}
                  </span>
                </template>

                <template #cell(status)="data">
                  <span v-if="data.item.status === null">Menunggu Approval</span>
                  <span v-else-if="data.item.status == 1" class="text-success">
                    Disetujui
                  </span>
                  <span v-else class="text-danger">Ditolak</span>
                </template>

                <template #cell(receipt)="data">
                  <b-button :href="data.item.file" variant="info" target="_blank">
                    Cek Kwitansi
                  </b-button>
                </template>

                <!-- A virtual column -->
                <template #cell(action)="data">
                  <div style="min-width: 120px">
                    <b-button
                      v-if="data.item.status !== 0 && role_name === 'Pegawai'"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      v-b-popover.hover.bottom="'Edit'"
                      variant="warning"
                      size="sm"
                      class="btn-icon mr-1"
                      @click="gotoEditPage(data.item.id)"
                    >
                      <feather-icon icon="EditIcon" />
                    </b-button>

                    <b-button
                      v-if="data.item.status !== 0"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      v-b-popover.hover.bottom="'Delete'"
                      variant="danger"
                      size="sm"
                      class="btn-icon"
                      @click="confirmDelete(data.item.id)"
                    >
                      <feather-icon icon="TrashIcon" />
                    </b-button>
                  </div>
                </template>
              </b-table>
              <div class="mt-2">
                  <h6>Total Biaya</h6>
                  <h6>{{ totalCost | currency }}</h6>
              </div>
            </div>
          </b-card-body>
          <b-card-footer align="right" class="px-0">
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="reset"
              variant="outline-secondary"
              :to="{ name: 'pengeluaran-rills' }"
            >
              Kembali
            </b-button>
            <b-button
              v-if="Object.keys(sppd).length && !sppd.real_expense_step"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              class="ml-1"
              variant="primary"
              @click="sendData"
            >
              {{ items.length ? 'Kirim' : 'Simpan' }}
            </b-button>
          </b-card-footer>
        </b-card>
      </b-col>
    </b-row>
  </b-overlay>
</template>

<script>
import {
  BCard,
  BCardBody,
  BCardFooter,
  BRow,
  BCol,
  BButton,
  BTable,
  VBPopover,
  BOverlay,
} from 'bootstrap-vue'
import { PlusIcon } from 'vue-feather-icons'
import Ripple from 'vue-ripple-directive'
import axios from '@axios'

export default {
  components: {
    BCard,
    BCardBody,
    BCardFooter,
    BRow,
    BCol,
    BButton,
    BTable,
    PlusIcon,
    BOverlay,
  },
  directives: {
    Ripple,
    'b-popover': VBPopover,
  },
  data() {
    return {
      sppdId: this.$route.params.sppdId,
      sppd: {},
      config: {
        api: '/real-expenses',
      },
      fields: [
        'no',
        { key: 'cost_component.name', label: 'Komponen Biaya' },
        { key: 'note', label: 'Catatan' },
        { key: 'total_cost', label: 'Biaya' },
        { key: 'status', label: 'Status' },
        { key: 'receipt', label: 'Kwitansi' },
      ],
      items: [],
      isLoading: false,
      role_name: JSON.parse(localStorage.getItem('userData')).role_name,
    }
  },
  computed: {
    totalCost() {
      let total = 0
      this.items.forEach(item => {
        total += item.total_cost
      })
      return total
    },
    sppdFacilityType() {
      if (this.sppd.sppd_facility_type_id == 1) {
        return 'Perjalanan Dinas Biasa'
      }
      if (this.sppd.sppd_facility_type_id == 2) {
        return 'Perjalanan Dinas Fasilitas Khusus'
      }
      return '-'
    },
    employee() {
      return this.sppd.employee?.raw ? JSON.parse(this.sppd.employee.raw) : {}
    },
  },
  mounted() {
    const { query } = this.$route
    if (Object.keys(query).length) {
      if (query.event) {
        this.makeToast(query.event, query.title, query.text)
      }
      this.$router.replace({ query: null })
    }

    this.getData()
  },
  methods: {
    getData() {
      this.isLoading = true
      axios
        .get(`${this.config.api}/${this.sppdId}`)
        .then(res => {
          const { data } = res.data
          if (!data.real_expense_step) {
            this.fields.push({
              key: 'action',
              label: 'Aksi',
              thStyle: 'width: 120px',
            })
          }
          this.sppd = data
          this.items = data.real_expenses
          this.isLoading = false
        })
        .catch(error => {
          this.isLoading = false 
          console.error(error)

          var message = "";
          if(typeof error.response.data.message === 'object'){
            message = error.response.data.message.errors[0].msg
          } else {
            message = error.response.data.message
          }
          this.makeToast('danger', "Terjadi Kesalahan", message)
        });
    },
    tambahPengeluaran() {
      this.$router.push({
        name: 'add-pengeluaran-rill',
        params: { sppdId: this.sppdId },
        query: { folder: this.sppd.folder },
      })
    },
    sendData() {
      let title = 'Catatan'
      let text =
        'Tidak mengisi biaya/kwitansi, dapat diartikan bahwa tidak ada biaya yang Anda keluarkan selama perjalanan dinas ini'

      if (this.items.length) {
        title = 'Perhatian'
        text =
          'Saya menyatakan dokumen yang diupload adalah benar/sah. Jika tidak benar, maka saya bersedia dikenakan sanksi/hukdis sesuai dengan ketentuan yang berlaku'
      }

      this.$swal({
        title,
        text,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Ya, setuju',
        cancelButtonText: 'Tidak, batal',
        buttonsStyling: false,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-2',
        },
      }).then(result => {
        if (result.value) {
          this.isLoading = true
          axios
            .put(`/sppd/${this.sppdId}/real-expense/submit`)
            .then(() => {
              this.isLoading = false
              this.fields.pop()
              this.getData()
              this.makeToast(
                'success',
                'Update Pengeluaran Riil',
                'Pengeluaran riil berhasil dikirim',
              )
            })
            .catch(err => {
              this.isLoading = false
              if (err.response) {
                this.$swal({
                  icon: 'Danger',
                  title: 'Gagal mengirim data',
                  text: err.response.data.message,
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                })
              }
            })
        }
      })
    },
    makeToast(variant = null, title = 'Title here...', text = 'Text here...') {
      this.$bvToast.toast(text, {
        title,
        variant,
        solid: true,
      })
    },
    gotoEditPage(id) {
      this.$router.push({
        name: 'edit-pengeluaran-rills',
        params: { sppdId: this.sppdId, sppdPengeluaranRiilId: id },
        query: { folder: this.sppd.folder },
      })
    },
    confirmDelete(id) {
      this.$swal({
        title: 'Apakah Anda Yakin',
        text: 'untuk menghapus data?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Ya',
        cancelButtonText: 'Batal',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          axios
            .delete(`${this.config.api}/${id}`)
            .then(() => {
              this.$swal({
                icon: 'success',
                title: 'Berhasil Dihapus',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
              this.items = this.items.filter(item => item.id !== id)
            })
            .catch(err => {
              if (err.response) {
                this.$swal({
                  icon: 'Danger',
                  title: 'Tidak bisa di hapus',
                  text: err.response.data.message,
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                })
              }
            })
        }
      })
    },
  },
}
</script>
